import {
  CREATE_RESELLER_REQUESTED,
  CREATE_RESELLER_SUCCESSFULL,
  CREATE_RESELLER_ERRORED,
  CREATE_SUPPLIER_REQUESTED,
  CREATE_SUPPLIER_SUCCESSFULL,
  CREATE_SUPPLIER_NO_RESELLERS_SUCCESSFULL,
  CREATE_SUPPLIER_ERRORED,
  CHECK_RESELLER_ELIGIBILITY_REQUESTED,
  CHECK_RESELLER_ELIGIBILITY_SUCCESSFULL,
  CHECK_RESELLER_ELIGIBILITY_ERRORED,
  UPDATE_PARTNER_REGIONS_REQUESTED,
  UPDATE_PARTNER_REGIONS_SUCCESSFULL,
  UPDATE_PARTNER_REGIONS_ERRORED,
  GET_PARTNER_RELATIONS_REQUESTED,
  GET_PARTNER_RELATIONS_SUCCESSFULL,
  GET_PARTNER_RELATIONS_ERRORED,
  GET_PAGINATED_MESSAGES_REQUESTED,
  GET_PAGINATED_MESSAGES_SUCCESSFULL,
  GET_PAGINATED_MESSAGES_ERRORED,
  GET_LATEST_MESSAGES_REQUESTED,
  GET_LATEST_MESSAGES_SUCCESSFULL,
  GET_LATEST_MESSAGES_ERRORED,
  GET_LAST_MESSAGE_DATE_REQUESTED,
  GET_LAST_MESSAGE_DATE_SUCCESSFULL,
  GET_LAST_MESSAGE_DATE_ERRORED,
  POST_PARTNER_MESSAGE_REQUESTED,
  POST_PARTNER_MESSAGE_SUCCESSFULL,
  POST_PARTNER_MESSAGE_ERRORED,
  SIGN_CONTRACT_REQUESTED,
  SIGN_CONTRACT_SUCCESSFULL,
  SIGN_CONTRACT_ERRORED,
  PARTNER_NOTICE_DISPATCH_REQUESTED,
  PARTNER_NOTICE_DISPATCH_SUCCESSFULL,
  PARTNER_NOTICE_DISPATCH_ERRORED,
  PARTNER_NOTICE_PROPOSAL_REQUESTED,
  PARTNER_NOTICE_PROPOSAL_SUCCESSFULL,
  PARTNER_NOTICE_PROPOSAL_ERRORED,
  OPEN_DISPUTE_REQUESTED,
  OPEN_DISPUTE_SUCCESSFULL,
  OPEN_DISPUTE_ERRORED,
  CHANGE_RESELLER_REQUESTED,
  CHANGE_RESELLER_SUCCESSFULL,
  CHANGE_RESELLER_ERRORED,
  SUPPLIER_DROPOUT_REQUESTED,
  SUPPLIER_DROPOUT_SUCCESSFULL,
  SUPPLIER_DROPOUT_ERRORED
} from '../actions/types';

const defaultState = {
  status: null,
  error: {},
  isResellerEligible: null,
  relations: [],
  relationSigned: null,
  lastMessageDate: null,
  messages: [],
  hasNextPage: true,
  loadMore: false
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case CREATE_RESELLER_REQUESTED: {
      return {
        ...state,
        status: CREATE_RESELLER_REQUESTED
      };
    }
    case CREATE_RESELLER_SUCCESSFULL: {
      return {
        ...state,
        status: CREATE_RESELLER_SUCCESSFULL
      };
    }
    case CREATE_RESELLER_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: CREATE_RESELLER_ERRORED
      };
    }
    case CREATE_SUPPLIER_REQUESTED: {
      return {
        ...state,
        status: CREATE_SUPPLIER_REQUESTED
      };
    }
    case CREATE_SUPPLIER_SUCCESSFULL: {
      return {
        ...state,
        status: CREATE_SUPPLIER_SUCCESSFULL
      };
    }
    case CREATE_SUPPLIER_NO_RESELLERS_SUCCESSFULL: {
      return {
        ...state,
        status: CREATE_SUPPLIER_NO_RESELLERS_SUCCESSFULL
      };
    }
    case CREATE_SUPPLIER_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: CREATE_SUPPLIER_ERRORED
      };
    }
    case CHECK_RESELLER_ELIGIBILITY_REQUESTED: {
      return {
        ...state,
        isResellerEligible: null,
        status: CHECK_RESELLER_ELIGIBILITY_REQUESTED
      };
    }
    case CHECK_RESELLER_ELIGIBILITY_SUCCESSFULL: {
      return {
        ...state,
        isResellerEligible: action.payload,
        status: CHECK_RESELLER_ELIGIBILITY_SUCCESSFULL
      };
    }
    case CHECK_RESELLER_ELIGIBILITY_ERRORED: {
      return {
        ...state,
        error: action.error,
        isResellerEligible: null,
        status: CHECK_RESELLER_ELIGIBILITY_ERRORED
      };
    }
    case UPDATE_PARTNER_REGIONS_REQUESTED: {
      return {
        ...state,
        status: UPDATE_PARTNER_REGIONS_REQUESTED
      };
    }
    case UPDATE_PARTNER_REGIONS_SUCCESSFULL: {
      return {
        ...state,
        status: UPDATE_PARTNER_REGIONS_SUCCESSFULL
      };
    }
    case UPDATE_PARTNER_REGIONS_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: UPDATE_PARTNER_REGIONS_ERRORED
      };
    }
    case GET_PARTNER_RELATIONS_REQUESTED: {
      return {
        ...state,
        status: GET_PARTNER_RELATIONS_REQUESTED
      };
    }
    case GET_PARTNER_RELATIONS_SUCCESSFULL: {
      return {
        ...state,
        relations: action.payload,
        status: GET_PARTNER_RELATIONS_SUCCESSFULL
      };
    }
    case GET_PARTNER_RELATIONS_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: GET_PARTNER_RELATIONS_ERRORED
      };
    }
    case GET_PAGINATED_MESSAGES_REQUESTED: {
      return {
        ...state,
        status: GET_PAGINATED_MESSAGES_REQUESTED
      };
    }
    case GET_PAGINATED_MESSAGES_SUCCESSFULL: {
      const { data, relationId, userType, loadMore } = action.payload;
      const { messages, hasNextPage } = data;
      const relationsUpdated = state.relations.map(relation => {
        if (relation.id === relationId) {
          return {
            ...relation,
            chat_seen_by_supplier:
              userType === 'Supplier' ? true : relation.chat_seen_by_supplier,
            chat_seen_by_reseller:
              userType === 'Reseller' ? true : relation.chat_seen_by_reseller
          };
        }
        return relation;
      });
      return {
        ...state,
        loadMore,
        hasNextPage,
        messages: [...(loadMore ? state.messages : []), ...messages],
        relations: relationsUpdated,
        status: GET_PAGINATED_MESSAGES_SUCCESSFULL
      };
    }
    case GET_PAGINATED_MESSAGES_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: GET_PAGINATED_MESSAGES_ERRORED
      };
    }
    case GET_LATEST_MESSAGES_REQUESTED: {
      return {
        ...state,
        status: GET_LATEST_MESSAGES_REQUESTED
      };
    }
    case GET_LATEST_MESSAGES_SUCCESSFULL: {
      const newMessages = action.payload
        .filter(message => !state.messages.some(m => m.id === message.id))
        .reverse();

      return {
        ...state,
        messages: [...newMessages, ...state.messages],
        status: GET_LATEST_MESSAGES_SUCCESSFULL
      };
    }
    case GET_LATEST_MESSAGES_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: GET_LATEST_MESSAGES_ERRORED
      };
    }
    case GET_LAST_MESSAGE_DATE_REQUESTED: {
      return {
        ...state,
        status: GET_LAST_MESSAGE_DATE_REQUESTED
      };
    }
    case GET_LAST_MESSAGE_DATE_SUCCESSFULL: {
      return {
        ...state,
        lastMessageDate: action.payload,
        status: GET_LAST_MESSAGE_DATE_SUCCESSFULL
      };
    }
    case GET_LAST_MESSAGE_DATE_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: GET_LAST_MESSAGE_DATE_ERRORED
      };
    }
    case POST_PARTNER_MESSAGE_REQUESTED: {
      return {
        ...state,
        status: POST_PARTNER_MESSAGE_REQUESTED
      };
    }
    case POST_PARTNER_MESSAGE_SUCCESSFULL: {
      return {
        ...state,
        status: POST_PARTNER_MESSAGE_SUCCESSFULL
      };
    }
    case POST_PARTNER_MESSAGE_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: POST_PARTNER_MESSAGE_ERRORED
      };
    }
    case SIGN_CONTRACT_REQUESTED: {
      return {
        ...state,
        status: SIGN_CONTRACT_REQUESTED
      };
    }
    case SIGN_CONTRACT_SUCCESSFULL: {
      return {
        ...state,
        relationSigned: action.payload,
        status: SIGN_CONTRACT_SUCCESSFULL
      };
    }
    case SIGN_CONTRACT_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: SIGN_CONTRACT_ERRORED
      };
    }
    case PARTNER_NOTICE_DISPATCH_REQUESTED: {
      return {
        ...state,
        status: PARTNER_NOTICE_DISPATCH_REQUESTED
      };
    }
    case PARTNER_NOTICE_DISPATCH_SUCCESSFULL: {
      return {
        ...state,
        status: PARTNER_NOTICE_DISPATCH_SUCCESSFULL
      };
    }
    case PARTNER_NOTICE_DISPATCH_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: PARTNER_NOTICE_DISPATCH_ERRORED
      };
    }
    case PARTNER_NOTICE_PROPOSAL_REQUESTED: {
      return {
        ...state,
        status: PARTNER_NOTICE_PROPOSAL_REQUESTED
      };
    }
    case PARTNER_NOTICE_PROPOSAL_SUCCESSFULL: {
      return {
        ...state,
        status: PARTNER_NOTICE_PROPOSAL_SUCCESSFULL
      };
    }
    case PARTNER_NOTICE_PROPOSAL_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: PARTNER_NOTICE_PROPOSAL_ERRORED
      };
    }
    case OPEN_DISPUTE_REQUESTED: {
      return {
        ...state,
        status: OPEN_DISPUTE_REQUESTED
      };
    }
    case OPEN_DISPUTE_SUCCESSFULL: {
      return {
        ...state,
        status: OPEN_DISPUTE_SUCCESSFULL
      };
    }
    case OPEN_DISPUTE_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: OPEN_DISPUTE_ERRORED
      };
    }
    case CHANGE_RESELLER_REQUESTED: {
      return {
        ...state,
        status: CHANGE_RESELLER_REQUESTED
      };
    }
    case CHANGE_RESELLER_SUCCESSFULL: {
      return {
        ...state,
        status: CHANGE_RESELLER_SUCCESSFULL
      };
    }
    case CHANGE_RESELLER_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: CHANGE_RESELLER_ERRORED
      };
    }
    case SUPPLIER_DROPOUT_REQUESTED: {
      return {
        ...state,
        status: SUPPLIER_DROPOUT_REQUESTED
      };
    }
    case SUPPLIER_DROPOUT_SUCCESSFULL: {
      return {
        ...state,
        status: SUPPLIER_DROPOUT_SUCCESSFULL
      };
    }
    case SUPPLIER_DROPOUT_ERRORED: {
      return {
        ...state,
        error: action.error,
        status: SUPPLIER_DROPOUT_ERRORED
      };
    }
    default:
      return state;
  }
}
