import { createMuiTheme } from '@material-ui/core/styles';
import MontserratRegular from './styling/fonts/Montserrat-Regular.woff';
import MontserratMedium from './styling/fonts/Montserrat-Medium.woff';
import MontserratLight from './styling/fonts/Montserrat-Light.woff';
import MontserratBold from './styling/fonts/Montserrat-Bold.woff';

const montserratLight = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
    url(${MontserratLight}) format('woff')
  `
};

const montserratRegular = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url(${MontserratRegular}) format('woff')
  `
};

const montserratMedium = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `
    url(${MontserratMedium}) format('woff')
  `
};

const montserratBold = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    url(${MontserratBold}) format('woff')
  `
};

const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
      disableTouchRipple: true
    }
  },
  shape: {
    borderRadius: 15
  },
  sizes: {
    singleScreenDesktopHeight: 'calc(100vh - 80px)',
    singleScreenMobileHeight: 'calc(100vh - 56px)'
  },
  palette: {
    primary: {
      light: '#f7ece2',
      main: '#f15f67',
      background: '#ebebeb'
    },
    text: {
      primary: '#414042'
    },
    secondary: {
      other: '#f8f8f8',
      light: '#a8a6a6',
      main: '#707070',
      dark: '#414042',
      darker: '#1a1a1a',
      contrastText: '#fdfbfc'
    },
    yellow: {
      lighter: '#FCF9E2',
      light: '#F6F5B7',
      main: '#EEDF6C',
      dark: '#e8d85f'
    },
    orange: {
      light: '#fcc874',
      main: '#EDBB6A',
      dark: '#eac944'
    },
    green: {
      darker: '#3DAFAB',
      dark: '#7FB2AC',
      main: '#A7D6A2',
      light: '#EDF6ED'
    },
    gradients: {
      showroom: ['#B0CAFB', '#AFE1BC'],
      premium: ['#FAACA8', '#BE6DF7'],
      celebrity: ['#FAD2A5', '#EE6184'],
      vip: ['#B2C8FD', '#F3BDBD'],
      regular: ['#F4ABA7', '#FEF0AD'],
      solidary: ['#79D9A3', '#99C8D1']
    }
  },
  typography: {
    fontFamily: 'Montserrat, Arial',
    fontSize: 12,
    letterSpacing: '-0.03rem',
    body1: {
      fontSize: '0.825rem',
      lineHeight: '1.7'
    },
    body2: {
      fontSize: '0.75rem',
      lineHeight: '1.7'
    },
    button: {
      fontWeight: 700,
      textTransform: 'none'
    }
  },
  overrides: {
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: '$labelColor'
        }
      }
    },
    MuiInputBase: {
      input: {
        padding: '6px 0 2px 0',
        fontSize: '15px',
        lineHeight: '16px'
      }
    },
    MuiInputLabel: {
      root: {
        color: '#414042',
        fontSize: '18px'
      },
      asterisk: {
        display: 'none'
      }
    },
    MuiButton: {
      root: {
        fontSize: '1rem'
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none'
        },
        '&:active': {
          boxShadow: 'none'
        }
      }
    },
    MuiSvgIcon: {
      root: {
        fontSize: '1.5rem'
      },
      fontSizeSmall: {
        fontSize: '1.25rem'
      }
    },
    MuiFormLabel: {
      root: {
        fontWeight: 500
      }
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          letterSpacing: '-0.03rem',
          backgroundColor: '#fff'
        },
        '#announcements': {
          display: 'none'
        },
        'a.MuiButton-root': {
          display: '-webkit-box',
          '-webkit-box-align': 'center',
          '-webkit-box-orient': 'block-axis',
          '& .MuiButton-label': {
            width: 'unset'
          }
        },
        '.MuiButton-root': {
          padding: '6px 16px 6px 16px'
        },
        '.MuiButton-label': {
          padding: 0,
          fontSize: '0.75rem'
        },
        'label + .MuiInput-formControl': {
          marginTop: '21px'
        },
        '.MuiButton-contained:hover': {
          boxShadow: 'none'
        },
        '.MuiButton-contained:active': {
          boxShadow: 'none'
        },
        '.MuiPaper-rounded': {
          borderRadius: '5px !important',
          boxShadow: '0 3px 15px 0 rgba(0, 0, 0, 0.1) !important',
          backgroundColor: '#ffffff'
        },
        '.MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track': {
          backgroundColor: '#9e9e9e !important'
        },
        '.MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track': {
          opacity: '1 !important'
        },
        '@font-face': [
          montserratLight,
          montserratRegular,
          montserratMedium,
          montserratBold
        ]
      }
    }
  }
});

export default theme;
