import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';

const useStyles = props =>
  makeStyles(theme => ({
    root: {
      position: 'absolute',
      zIndex: 1,
      color: props.isRTVPro
        ? theme.palette.green.dark
        : theme.palette.primary.main
    }
  }));

const SpinnerAdornment = ({ isRTVPro }) => {
  const classes = useStyles({ isRTVPro })();
  return <CircularProgress className={classes.root} size={20} />;
};

const AdornedButton = props => {
  const { children, loading, ...rest } = props;

  return (
    <Button style={{ position: 'relative' }} {...rest}>
      {children}
      {loading && <SpinnerAdornment {...rest} />}
    </Button>
  );
};

export default AdornedButton;
