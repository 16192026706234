import {
  GET_SETTINGS_REQUESTED,
  GET_SETTINGS_SUCCESFULL,
  GET_SETTINGS_ERRORED
} from '../actions/types';

const defaultState = {
  status: '',
  loading: false,
  metadata: null
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case GET_SETTINGS_REQUESTED: {
      return {
        ...state,
        loading: true,
        status: GET_SETTINGS_REQUESTED
      };
    }
    case GET_SETTINGS_SUCCESFULL: {
      return {
        ...state,
        loading: false,
        status: GET_SETTINGS_SUCCESFULL,
        metadata: action.metadata
      };
    }
    case GET_SETTINGS_ERRORED: {
      return {
        ...state,
        loading: false,
        status: GET_SETTINGS_ERRORED
      };
    }
    default:
      return state;
  }
}
