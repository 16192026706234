import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import app from './app';
import auth from './auth';
import user from './user';
import categories from './categories';
import social from './social';
import product from './product';
import shipping from './shipping';
import favourite from './favourite';
import comments from './comments';
import publicity from './publicity';
import order from './order';
import financial from './financial';
import communication from './communication';
import device from './device';
import partner from './partner';
import settings from './settings';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    app,
    auth,
    user,
    categories,
    social,
    product,
    shipping,
    favourite,
    comments,
    publicity,
    order,
    financial,
    communication,
    device,
    partner,
    settings
  });

export default createRootReducer;
